.sm-simple {
  border: 1px solid #bbbbbb;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.sm-simple a, .sm-simple a:hover, .sm-simple a:focus, .sm-simple a:active {
  padding: 13px 20px;
  /* make room for the toggle button (sub indicator) */
  padding-right: 58px;
  color: #555555;
  font-family: "Lucida Sans Unicode", "Lucida Sans", "Lucida Grande", Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 17px;
  text-decoration: none;
}
.sm-simple a.current {
  background: #555555;
  color: #fff;
}
.sm-simple a.disabled {
  color: #cccccc;
}
.sm-simple a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 4px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 14px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  background: rgba(0, 0, 0, 0.08);
}
.sm-simple a .sub-arrow::before {
  content: '+';
}
.sm-simple a.highlighted .sub-arrow::before {
  content: '-';
}
.sm-simple li {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.sm-simple > li:first-child {
  border-top: 0;
}
.sm-simple ul {
  background: rgba(179, 179, 179, 0.1);
}
.sm-simple ul a, .sm-simple ul a:hover, .sm-simple ul a:focus, .sm-simple ul a:active {
  font-size: 14px;
  border-left: 8px solid transparent;
}
.sm-simple ul ul a,
.sm-simple ul ul a:hover,
.sm-simple ul ul a:focus,
.sm-simple ul ul a:active {
  border-left: 16px solid transparent;
}
.sm-simple ul ul ul a,
.sm-simple ul ul ul a:hover,
.sm-simple ul ul ul a:focus,
.sm-simple ul ul ul a:active {
  border-left: 24px solid transparent;
}
.sm-simple ul ul ul ul a,
.sm-simple ul ul ul ul a:hover,
.sm-simple ul ul ul ul a:focus,
.sm-simple ul ul ul ul a:active {
  border-left: 32px solid transparent;
}
.sm-simple ul ul ul ul ul a,
.sm-simple ul ul ul ul ul a:hover,
.sm-simple ul ul ul ul ul a:focus,
.sm-simple ul ul ul ul ul a:active {
  border-left: 40px solid transparent;
}

@media (min-width: 768px) {
  /* Switch to desktop layout
  -----------------------------------------------
     These transform the menu tree from
     collapsible to desktop (navbar + dropdowns)
  -----------------------------------------------*/
  /* start... (it's not recommended editing these rules) */
  .sm-simple ul {
    position: absolute;
    width: 12em;
  }

  .sm-simple li {
    float: left;
  }

  .sm-simple.sm-rtl li {
    float: right;
  }

  .sm-simple ul li, .sm-simple.sm-rtl ul li, .sm-simple.sm-vertical li {
    float: none;
  }

  .sm-simple a {
    white-space: nowrap;
  }

  .sm-simple ul a, .sm-simple.sm-vertical a {
    white-space: normal;
  }

  .sm-simple .sm-nowrap > li > a, .sm-simple .sm-nowrap > li > :not(ul) a {
    white-space: nowrap;
  }

  /* ...end */
  .sm-simple {
    background: #fff;
  }
  .sm-simple a, .sm-simple a:hover, .sm-simple a:focus, .sm-simple a:active, .sm-simple a.highlighted {
    padding: 11px 20px;
    color: #555555;
  }
  .sm-simple a:hover, .sm-simple a:focus, .sm-simple a:active, .sm-simple a.highlighted {
    background: #eeeeee;
  }
  .sm-simple a.current {
    background: #555555;
    color: #fff;
  }
  .sm-simple a.disabled {
    background: #fff;
    color: #cccccc;
  }
  .sm-simple a.has-submenu {
    padding-right: 32px;
  }
  .sm-simple a .sub-arrow {
    top: 50%;
    margin-top: -8px;
    right: 20px;
    width: 8px;
    height: 16px;
    font: 14px/16px monospace !important;
    background: transparent;
  }
  .sm-simple a.highlighted .sub-arrow::before {
    content: '+';
  }
  .sm-simple > li {
    border-top: 0;
    border-left: 1px solid #eeeeee;
  }
  .sm-simple > li:first-child {
    border-left: 0;
  }
  .sm-simple ul {
    border: 1px solid #bbbbbb;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }
  .sm-simple ul a {
    border: 0 !important;
  }
  .sm-simple ul a.has-submenu {
    padding-right: 20px;
  }
  .sm-simple ul a .sub-arrow {
    left: 8px;
    right: auto;
  }
  .sm-simple ul > li {
    border-left: 0;
    border-top: 1px solid #eeeeee;
  }
  .sm-simple ul > li:first-child {
    border-top: 0;
  }
  .sm-simple .scroll-up,
  .sm-simple .scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    background: #fff;
    height: 20px;
  }
  .sm-simple .scroll-up-arrow,
  .sm-simple .scroll-down-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-width: 8px;
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent #555555 transparent;
  }
  .sm-simple .scroll-down-arrow {
    top: 6px;
    border-style: solid dashed dashed dashed;
    border-color: #555555 transparent transparent transparent;
  }
  .sm-simple.sm-rtl a.has-submenu {
    padding-right: 20px;
    padding-left: 32px;
  }
  .sm-simple.sm-rtl a .sub-arrow {
    left: 20px;
    right: auto;
  }
  .sm-simple.sm-rtl.sm-vertical a.has-submenu {
    padding: 11px 20px;
  }
  .sm-simple.sm-rtl.sm-vertical a .sub-arrow {
    left: auto;
    right: 8px;
  }
  .sm-simple.sm-rtl > li:first-child {
    border-left: 1px solid #eeeeee;
  }
  .sm-simple.sm-rtl > li:last-child {
    border-left: 0;
  }
  .sm-simple.sm-rtl ul a.has-submenu {
    padding: 11px 20px;
  }
  .sm-simple.sm-rtl ul a .sub-arrow {
    left: auto;
    right: 8px;
  }
  .sm-simple.sm-vertical a .sub-arrow {
    left: 8px;
    right: auto;
  }
  .sm-simple.sm-vertical li {
    border-left: 0;
    border-top: 1px solid #eeeeee;
  }
  .sm-simple.sm-vertical > li:first-child {
    border-top: 0;
  }
}


